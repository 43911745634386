@import "~gateway-common/src/styles/styles.scss";

.referral-content {
  width: 922px;
  background-color: transparent;
  margin-top: $spacing-30px;

  .referral-navbar {
    display: flex;
    height: 50px;
    width: 100%;
    background-color: $white;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid $neutral-light;

    .add-need-container {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
      margin-right: $spacing-24px;
  
      .add-need-btn {
        border: none;
        background-color: inherit;
        color: $primary-color-dark;
  
        &:hover {
          cursor: pointer;
          color: $primary-color-light;
        }
      }
    }
  }

  .tab-details-container {
    width: 100%;
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0px 2px 4px $neutral-light;

    .referral-timeline-container {
      width: 100%;
      padding: calc(36px - 6px) $spacing-20px $spacing-36px;
      background-color: $white;
    }
  }
}

@import "../../App.scss";

.user-management-container {
  margin: 28px 0 48px;
  .account-user-management-admin {
    width: 25%;
    height: 40px;
    background-color: #d7d7d7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__tab {
      width: 49%;
      height: 85%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 14px;
    }
    &__active {
      background-color: #ffffff;
      border-radius: 4px;
    }
  }
  .admin-pagination {
    ul {
      display: flex;
      justify-content: end;
      li {
        display: flex;
        list-style: none;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        width: 25px;
        height: 25px;
        margin: 0 4px;
        font-size: 14px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.active {
          background-color: #5886d4;
          color: #FFFFFF;
          &:hover {
            background-color: #1976d2;
            box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
          }
        }
        &.disabled {
          color:#FFFFFF ;
          background-color: #d7d7d7;
          cursor:not-allowed;
          pointer-events: none;
          &:hover {
            background-color: #d7d7d7;
            box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
          }
        }
        &:hover {
          color: #FFFFFF;
          background-color: #1976d2;
          box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
        }
      }
    }
  }
  .add-account-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: 15%;
      height: auto;
      padding: 0 4px;
    }
  }
  table.accounts-list-container {
    table-layout: fixed;
    background-color: #fff;
    border-collapse: collapse;
    border: 1px solid #d7d7d7;
    margin: 24px 0;
    width:100%;
    tbody tr:nth-child(odd) {
      background-color: #f2f2f2;
    }
    td, thead th {
      padding: 12px;
      word-wrap: break-word;
      text-align: left;
    }
    .sort_row{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .sort_icon{
      display: flex;
      flex-direction: column;
    }
    .down_icon{
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
    .down_icon_selected{
      width: 15px;
      height: 15px;
      opacity: 0.5;
      cursor:not-allowed;
      pointer-events: none;
    }
    .up_icon{
      width: 15px;  
      height: 15px;
      cursor: pointer;
    }
    .up_icon_selected{
      width: 15px;
      height: 15px;
      opacity: 0.5;
      cursor:not-allowed;
      pointer-events: none;
    }
    .sort_text_select{
      color: #2e1cef;
    }
    .button_table{
      width: 100%;
    }
    .table_text{
      margin-bottom: 10px;
      margin-left: 20px;
      align-items: center;
      justify-content: center;
    }
  }
  .account-creation-form-container {
    padding: 18px 28px;
    margin-bottom: 24px;
    background-color: #fff;
    border: 1px solid #d7d7d7;
    margin-top: 12px;
    .input-1 {
      margin-bottom: 12px;
      margin-top: 12px;
    }
  }
  .create-account-form {
    width: 390px;
    background-color: white;
    margin: 3.5em auto;
    padding: 2em 4em;
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    .field {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 1em;

      input {
        display: block;
        box-sizing: border-box;
        width: 100%;
        margin-top: 0.5em;
        padding: 9px 10px;
        border-radius: 3px;
        border: 1px solid #cbd6e2;
        font-size: 16px;
        transition: all 0.15s ease-out;
        background-color: #f5f8fa;
        color: #33475b;
        line-height: 22px;
      }

      .input-error {
        font-size: 12px;
        line-height: 18px;
        color: #f2545b;
        margin-top: 2px;
      }

      label {
        margin-top: 2px;
        display: block;
        text-align: left;

        .dropdown-component {
          height: 42px;
          margin-top: 0.5em;

          .dropdown-container {
            background-color: #f5f8fa;

            .dropdown-closed {
              height: 22px;
            }
          }
        }
      }

      .checkbox {
        margin: 2px 0px 2px;
        display: flex;
        align-items: center;

        input {
          width: 24px;
          margin: 0;
        }
      }
    }

    .error-message {
      padding: 8px 1em;
      background-color: #fdedee;
      border: 1px solid #f8a9ad;
      font-size: 14px;
      text-align: left;
    }

    .create-account-btn {
      button {
        width: 100%;
        height: 40px;
        margin: 1em 0;
        background-color: #1976d2;
        color: white;
        line-height: 16px;
        padding: 11px 24px;
        font-size: 18px;
        font-weight: 500;
        border-radius: 3px;
        cursor: pointer;
        transition: all 0.15s ease-out;

        &:hover {
          background-color: #5886d4;
        }
      }
    }
  }

  .successful-sign-up {
    width: 390px;
    background-color: white;
    margin: 3.5em auto;
    padding: 2em 4em;
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    text-align: center;

    .checkmark {
      color: #589051;
      font-size: 50px;
      margin-bottom: 20px;
    }

    .success-message {
      margin-bottom: 20px;
    }
  }
  .patient-search-container {
    display: flex;
    width: 57%;
    .search{
      width: 90%;
      margin-right: 20px;
    }

    .text-link {
      color: $grey-1;
    }
    .body-1-bold {
      color: $white;
    }
  }
}

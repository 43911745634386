.tag-urgent, .tag-routine, .tag-expedite, .tag-closed,
.tag-unassigned, .tag-ongoing, .tag-pending {
  display: flex;
  align-items: center;
  height: 24px;
  color: $white;
  padding: 0px 10px;
  font-family: HKGrotesk-Bold;
  font-size: 14px;
  line-height: 14px;
  border-radius: 3px;
}

.tag-urgent {
  background-color: $warning-dark;
}

.tag-routine, .tag-expedite {
  background-color: $grey-1;
}

.tag-closed {
  background-color: $success-dark;
}

.tag-unassigned, .tag-pending {
  background-color: $warning-light;
}

.tag-ongoing {
  background-color: $secondary-color-dark;
}

.user-profile-main-container {
  width: 100%;
  height: 100%;
  overflow: auto;

  .profile-container {
    display: flex;
    flex-direction: column;
    margin: 40px auto;
    background-color: inherit;
    width: 750px;
    min-width: 750px;
    border-radius: 3px;
    box-shadow: 0px 0px 0px 1px rgba(2, 2, 2, 0.295);
  
    .profile-title-bar {
      display: flex;
      justify-content: flex-start;
      background: rgba(0,0,0,.15);
      align-items: center;
      border-bottom: .1px solid rgb(178, 193, 201);
      padding: 12px 8px;

      p {
        padding: 8px 12px;
        color: white;
        font-weight: 600;
        font-size: 22px;
      }

      img {
        height: 30px;
        padding: 0px 16px;
        filter: invert(100%);
      }
    }

    .profile-content {
      display: flex;
      flex-direction: column;
      background-color: white;
      padding-top: 24px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;

      ul {
        display: flex;
        flex-direction: column;
        list-style-type: none;

        li {
          margin: 0px auto 24px auto;

          label {
            width: 100%;
            display: flex;
            justify-content: flex-start;

            .profile-valid-input-symbol {
              margin-left: 12px;

              img{
                width: 20px;
              }
            }

            .profile-change-password {
              display: flex;
              justify-content: center;
              width: 250px;

              input {
                width: auto;
                display: flex;
                background-color: white;
                text-align: center;
                color: #003cff;
                font-size: 14px;
                font-weight: 700;
                height: 30px;
                border: none;

                &:hover {
                  cursor: pointer;
                  opacity: .8;
                }
                &:focus {
                  outline: none;
                }
              }
            }

            p {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 300px;
              color: #33475b;
              font-size: 18px;
              font-weight: 600;
            }

            input {
              width: 250px;
              height: 30px;
              border-radius: 2px;
              border: 1px solid rgb(173, 173, 173);
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
      .profile-btn-container {
        display: flex;
        justify-content: center;
        margin-top: 16px;

        button {
          margin: 0px 12px 24px 12px;
          background-color: #3157d6;
          color: white;
          font-size: 14px;
          font-weight: 600;
          width: 120px;
          height: 30px;
          border: none;
          border-radius: 3px;
          box-shadow: 1px 1px 3px .2px rgb(17, 17, 17);

          &:hover {
            cursor: pointer;
            opacity: .9;
          }
          &:focus {
            outline: none;
          }
        }
      }
      .profile-error-msg-container {
        color: red;
        margin-bottom: 16px;
      }
    }
  }
}
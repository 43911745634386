@import '~gateway-common/src/styles/styles';

.lightbox {
    &.status-delete-lightbox {
      width: 470px;
      padding: 30px;
      .container {
        position: relative;
        .status-dropdown {
          margin-bottom: 24px;
          width: 100%;
        }
        .section-label {
          padding-bottom: 15px;
        }
        // footer
        > div:last-child {
          display: flex;
          align-items: center;
          // padding: $spacing-24px 0;
          // border-top: 1px solid $neutral-dark;
          
          .text-link {
            line-height: 16px;
            cursor: pointer;
            padding: $spacing-10px;
          }
        }
      }
    }
  }
.dark-background-container {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 12;

  .drafts-main-container {
    position: relative;
    height: 507.5px;
    width: 1065px;
    background-color: white;
    border-radius: 4.3px;
    box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
    margin-top: 125px;
    opacity: 1;
    z-index: 15;
    display: flex;
    flex-direction: column;

    .close-window-btn {
      position: absolute;
      right: 0px;
      top: -35px;
      height: 25px;
      width: 25px;
      border-radius: 100%;
      background-color: #dbdbdb;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: #ececec;
      }

      &:active {
        background-color: #d8d8d8;
      }

      svg {
        fill: #36393d;
        height: 12.5px;
        width: 12.5px;
      }
    }

    .header-container {
      display: flex;
      justify-content: space-between;
      padding: 18px 26px 13px 26px;
      align-items: center;
      border-bottom: solid 1px #e2e2e2;

      h3 {
        font-family: HKGrotesk-Bold;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.55px;
        color: #36393d;
      }

      .sort-container {
        position: relative;
        button {
          width: 50px;
          border: none;
          font-family: HKGrotesk-Bold;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.4px;
          color: #6d7278;
          background: url("../../assets/dropdown.png") no-repeat right;
          cursor: pointer;

          &:focus {
            outline: none;
          }
        }

        .dropdown-container {
          display: flex;
          flex-direction: column;
          position: absolute;
          z-index: 100;
          padding: 8px 0px 8px 0px;
          border-radius: 4px;
          box-shadow: 0 3px 6px 2px #e8ebf2;
          border: solid 0.5px #cfcfcf;
          background-color: #ffffff;

          &:focus {
            outline: none;
          }

          .dropdown-option {
            display: flex;
            white-space: nowrap;
            padding: 12px;
            font-family: HKGrotesk-SemiBold;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.4px;
            color: #36393d;
            padding-top: 4px;
            padding-bottom: 3px;
            width: 100%;

            &:hover {
              cursor: pointer;
              background-color: rgba(216, 216, 216, 0.32);
            }

            &:focus {
              outline: none;
            }
          }
        }
      }
    }

    .drafts-table-container {
      display: flex;
      flex-direction: column;

      .col-1 {
        width: 451.25px;
      }

      .col-2 {
        width: 378.75px;
      }

      .col-3 {
        width: 147.5px;
      }

      .column {
        display: flex;
        justify-content: flex-start;
      }

      .table-row {
        display: flex;
        padding: 15px 45px 17px 24px;
        border-bottom: solid 1px #e2e2e2;
      }

      .table-header {
        font-family: HKGrotesk-Bold;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.4px;
        color: #acacac;
      }

      .table-body {
        .table-row {
          .col-1 {
            font-family: HKGrotesk-Bold;
            font-size: 16px;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.48px;
            color: #404449;
          }

          .col-2 {
            font-family: HKGrotesk-Bold;
            font-size: 16px;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.48px;
            color: #959595;
          }

          .col-3 {
            display: flex;
            justify-content: space-between;

            button {
              border: none;
              background-color: inherit;
              font-family: HKGrotesk-Bold;
              font-size: 14px;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: -0.4px;
              cursor: pointer;

              &:focus {
                outline: none;
              }

              svg {
                margin-right: 8px;
              }
            }

            .edit-btn {
              color: #2730d3;

              &:hover {
                color: #4851da;

                svg {
                  fill: #4851da;
                }
              }

              &:focus {
                color: #2028b1;

                svg {
                  fill: #2028b1;
                }
              }

              svg {
                fill: #2730d3;
              }
            }

            .delete-btn {
              color: #e02020;

              svg {
                fill: #e02020;
              }

              &:hover {
                color: #e9492a;

                svg {
                  fill: #e9492a;
                }
              }

              &:focus {
                color: #c33d23;

                svg {
                  fill: #c33d23;
                }
              }
            }
          }
        }
      }
    }

    .bottom-nav-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: flex-end;
      justify-content: flex-end;
      padding-right: 34px;
      padding-bottom: 24px;

      .page-controls-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 84px;
        font-family: HKGrotesk-Bold;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.4px;
        color: #2730d3;
      }

      button {
        border: none;
        background-color: inherit;
        border-radius: 100%;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 14px;
          color: #2730d3;
        }

        &:focus {
          outline: none;
        }

        &[disabled] {
          i {
            color: #acacac;
          }
        }

        &:not([disabled]) {
          cursor: pointer;

          &:hover {
            background-color: #ececec;
          }

          &:focus {
            background-color: #d8d8d8;
          }
        }
      }
    }
  }
}

@import "~gateway-common/src/styles/styles.scss";

.attachment {
  width: 100%;
  border: 1px solid $neutral-dark;
  background-color: $grey-3;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;

  .file-icon {
    margin-right: 20px;
  }

  .attachment-text {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
    .attachment-link {

      color: $primary-color-dark;
      cursor: pointer;
  
      &:hover {
        color: $primary-color-light;
      }
    }
  }

  .delete-section {
    display: flex;
    align-items: center;
    border-left: 1px solid $neutral-dark;
    padding-left: $spacing-20px;
    margin-left: $spacing-20px;

    .delete-btn {
      color: $primary-color-dark;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        color: $primary-color-light;
      }
    }
  }
}

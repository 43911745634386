@import "~gateway-common/src/styles/styles.scss";

@keyframes slidein {
    from {
      max-height: 0px;
    }
  
    to {
      max-height: 100px;
    }
  }

.pipeline-site-table {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px #D7D7D7;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  div:last-child {
    .table-cell {
      border-bottom: none;
    }
  }

  .table-row {
    display: flex;

    &.site-row {
      &:not(.last-site) {
        .table-cell.pipelines-cell, .table-cell.spacer-cell {
          border-bottom: none;
        }
      }

      &.new-site {
        animation: .4s slidein;
        overflow: hidden;
      }

      .input-1 {
        margin-right: $spacing-16px;
      }
    }

    &.pipeline-row {
      .table-cell {
        padding: 16px 0px;
      }

      &.new-pipeline {
        animation: .4s slidein;
        overflow: hidden;
      }
    }

    .table-cell {
      display: flex;
      align-items: center;
      padding: 12px 0px;
      border-bottom: 1px solid $neutral-dark;
      line-height: 33px;

      &.pipelines-cell {
        padding-left: 24px;
        width: 300px;
      }

      &.sites-cell {
        width: 400px;
      }

      &.users-cell {
        width: 300px;
      }

      &.spacer-cell {
        width: 200px;
      }

      .edit-btn {
        border: none;
        background-color: inherit;
        color: $primary-color-dark;
        margin-left: 12px;
        font-size: 14px;
  
        &:hover {
          cursor: pointer;
          color: $primary-color-light;
        }
      }
    }
  }
}
@import "./colors.scss";
@import "./mixins.scss";
@import "./typography.scss";

.button-secondary-big-2 {
  width: 160px;
  height: 30px;
  background-color: $white;
  border: 1px solid $grey-1;
  color: $grey-1;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: $grey-3;
    color: $black;
    border-color: $black;
  }
}

.button {
  &-primary-big,
  &-primary-small {
    background-color: $primary-color-dark;
    border: none;
    cursor: pointer;
    color: $white;
    @include flexer($just: center, $ai: center);
    &:hover {
      background-color: $primary-color-light;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
      .text-link {
        color: $white;
        white-space: nowrap;
      }
    }
    &.inactive,
    &.inactive:hover,
    &.has-error,
    &.has-error:hover {
      background-color: $neutral-light;
      pointer-events: none;
      cursor: not-allowed;
    }
    &.has-error {
      border: 1px solid $warning-dark;
    }
    .text-link {
      color: $white;
      white-space: nowrap;
    }
  }
  &-primary {
    &-big {
      width: 200px;
      height: 45px;
      border-radius: 30px;
      font-size: 16px;
      line-height: 30px;
    }
    &-small {
      border-radius: 20px;
      font-size: 14px;
      line-height: 33px;
      width: 160px;
      height: 30px;
    }
  }
  &-circle-big {
    &-primary,
    &-secondary-1,
    &-secondary-2 {
      @include flexer($ai: center, $just: center);
      border-radius: 50%;
      border-width: 1px;
      border-style: solid;
      width: 43.5px;
      height: 43.5px;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      }
      &-disabled {
        background-color: $neutral-light;
        border-radius: 50%;
        border: 1px solid $neutral-light;
        width: 43.5px;
        height: 43.5px;
        cursor: not-allowed;

        svg path {
          stroke: $white;
        }
      }
    }

    &-primary {
      background-color: $primary-color-dark;
      border-color: $primary-color-dark;
      &:hover {
        background-color: $primary-color-light;
        border-color: $primary-color-light;
      }
      svg path {
        stroke: $white;
      }
    }

    &-secondary-1,
    &-secondary-2 {
      background-color: $white;
      &:hover {
        background-color: $bg-grey;
      }
    }

    &-secondary-1 {
      border-color: $primary-color-dark;

      svg path {
        stroke: $primary-color-dark;
      }

      &:hover {
        border-color: $primary-color-light;

        svg path {
          stroke: $primary-color-light;
        }
      }
    }

    &-secondary-2 {
      border-color: $grey-1;

      svg path {
        stroke: $grey-1;
      }

      &:hover {
        border-color: $black;

        svg path {
          stroke: $black;
        }
      }
    }
  }
}

.button {
  &-secondary-small {
    &-1,
    &-2 {
      background-color: $white;
      width: 160px;
      height: 30px;
      border-radius: 20px;
      cursor: pointer;
      @include flexer($ai: center, $just: center);

      .text-link-container {
        justify-content: center;
        width: 100%;
      }

      .text-link {
        white-space: nowrap;
      }

      &:hover {
        background-color: $bg-grey;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      }
    }

    &-1-onnav,
    &-2-onnav {
      width: 160px;
      height: 30px;
      border-radius: 20px;
      cursor: pointer;
      @include flexer($ai: center, $just: center);

      .text-link-container {
        justify-content: center;
        width: 100%;
      }

      &:hover {
        background-color: $bg-grey;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      }

      background-color: $primary-color-dark;
      border: 1px solid $primary-color-dark;

      .text-link {
        color: $white;
      }
    }

    &-1-onpage,
    &-2-onpage {
      background-color: $primary-color-dark;
      border: 1px solid $primary-color-dark;
      width: 160px;
      height: 30px;
      border-radius: 20px;
      cursor: pointer;
      @include flexer($ai: center, $just: center);

      .text-link-container {
        justify-content: center;
        width: 100%;
      }

      .text-link {
        white-space: nowrap;
        color: $white;
      }

      &:hover {
        .text-link {
          color: $white;
        }
      }
    }

    &-1 {
      border: 1px solid $primary-color-dark;

      &:hover {
        border: 1px solid $primary-color-light;
      }

      .text-link {
        color: $primary-color-dark;

        &:hover {
          color: $primary-color-light;
        }
      }
    }

    &-2 {
      border: 1px solid $grey-1;

      &:hover {
        border: 1px solid $black;
      }

      .text-link {
        color: $grey-1;

        &:hover {
          color: $black;
        }
      }
    }
  }
}

@import "../../App.scss";

.parent-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .nav-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
  }

  .children-container {
    margin: 60px auto 0px;
    background-color: $bg-grey;
  }
}

@import '../../App.scss';

.lightbox {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  background-color: #fff;
  .button-primary-small {
    font-family: HKGrotesk-SemiBold, sans-serif;
  }
  .btn-close {
    position: absolute;
    right: -45px;
    top: 0;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    background-color: $grey-3;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
  }
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(46, 46, 46, 0.5);
  z-index: 12;
}

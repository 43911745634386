@import "../../App.scss";

.action-card-dashboard,
.action-card-pipeline {
  background-color: $white;
  padding: $spacing-36px $spacing-24px;
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  @include flexer($dir: column, $just: space-between);

  .title-2 {
    color: $grey-1;
  }

  img {
    margin-left: 50px;
  }

  hr {
    width: 100%;
    border: 1px solid $neutral-dark;
  }

  .action-card-top-section {
    @include flexer($dir: row, $just: space-between, $ai: center);
    margin-bottom: $spacing-24px;

    &-info {
      @include flexer($dir: column, $ai: flex-start);
    }

    h2 {
      margin-bottom: $spacing-24px;
    }

    .action-card-summary {
      @include flexer($dir: row, $ai: center);

      &-value {
        margin-right: $spacing-24px;
        color: $secondary-color-dark;
      }

      .is-pending-referral {
        color: $warning-light;
      }

      .is-inactive {
        color: $neutral-light;
      }

      &-text {
        word-break: normal;
        text-align: left;
      }
    }
  }

  .action-card-array {
    @include flexer($dir: column, $ai: center);
    margin-top: $spacing-24px;

    &-element {
      width: 100%;
      @include flexer($dir: row, $ai: center);
      margin-bottom: $spacing-24px;

      &-label {
        text-align: left;
      }

      p.title-1 {
        font-size: 24px;
      }

      p.body-2 {
        color: $grey-1;
      }

      &-data,
      &-link {
        width: 50%;
      }

      &-data {
        @include flexer($dir: row, $just: flex-start, $ai: center);
      }

      &-value.title-1 {
        margin-right: $spacing-24px;
        color: $secondary-color-dark;
      }

      .is-pending-referral {
        color: $warning-light;
      }

      .is-inactive {
        color: $neutral-light;
      }

      &-link {
        @include flexer($dir: row, $just: flex-end, $ai: center);

        .is-active {
          cursor: pointer;
        }

        .is-inactive {
          pointer-events: none;
          cursor: not-allowed;
        }

        &-text {
          margin-right: 10px;
        }
      }
    }
  }

  .action-card-button {
    margin-top: $spacing-36px;
    border-radius: 30px;
    border: none;
    width: 200px;
    height: 47px;
    text-align: center;
    @include flexer($as: center, $just: center, $ai: center);
  }

  button.action-card-button.text-link {
    color: $white;
  }

  .is-active-button {
    background-color: $primary-color-dark;
    cursor: pointer;
  }

  .is-inactive-button {
    background-color: $neutral-light;
    pointer-events: none;
    cursor: not-allowed;
  }
}

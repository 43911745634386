// Brand Colors
$primary-color-dark: #2e1cef;
$primary-color-light: #5545ff;
$secondary-color-dark: #068bb7;
$secondary-color-light: #00cde8;

// Secondary Colors
$warning-dark: #e02020;
$warning-light: #ff5e52;
$success-dark: #1abd6d;
$success-light: #36e18d;
$neutral-dark: #7d939e;
$neutral-light: #d7d7d7;
$black: #000000;
$secondary-dark: #25384f;
$grey-1: #2e2e2e;
$grey-2: #7f7f7f;
$grey-3: #f6f6f6;
$grey-4: #bfbdbd;
$bg-grey: #f6f6f6;
$white: #ffffff;
$grey-5: #f3f3f3;

:export {
  primaryColorDark: $primary-color-dark;
  primaryColorLight: $primary-color-light;
  secondaryColorDark: $secondary-color-dark;
  secondaryColorLight: $secondary-color-light;
  warningDark: $warning-dark;
  secondaryDark: $secondary-dark;
  warningLight: $warning-light;
  successDark: $success-dark;
  successLight: $success-light;
  neutralDark: $neutral-dark;
  neutralLight: $neutral-light;
  black: $black;
  grey1: $grey-1;
  grey2: $grey-2;
  grey3: $grey-3;
  grey4: $grey-4;
  grey5: $grey-5;
  bgGrey: $bg-grey;
  white: $white;
}

@import '~gateway-common/src/styles/styles';

.lightbox.edit-lightbox {
  // hard-coded width as per design requirement
  width: 470px;
  .container {
    // header
    > div:first-child {
      padding: $spacing-24px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid $neutral-dark;

      // Key-Value edit
      &.key-value {
        padding: $spacing-12px $spacing-36px;
        justify-content: space-between;
      }
    }

    // inner content
    .content {
      max-height: 65vh;
      overflow-y: scroll;
      padding: 0;
      .edit-form {
        > div {
          padding: $spacing-24px $spacing-36px;
          &:not(:last-child) {
            border-bottom: 1px solid $neutral-dark;
          }
          > div {
            &:not(:last-child) {
              margin-bottom: $spacing-10px;
            }
            .input-1 {
              font-family: HKGrotesk-Medium;
            }
          }
        }
      }
    }

    // footer
    > div:last-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $spacing-24px $spacing-36px;
      border-top: 1px solid $neutral-dark;

      .text-link {
        line-height: 16px;
        cursor: pointer;
      }

      button {
        margin-bottom: $spacing-12px;
      }
    }
  }
}

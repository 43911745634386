@import "~gateway-common/src/styles/styles.scss";

.referral-timeline-container {
  width: 100%;
  min-height: 800px;

  .needs-tabs-container {
    width: 100%;
    @include flexer($dir: row, $ai: center, $just: flex-start, $wrap: wrap);

    .button-secondary-small-2 {
      margin-right: $spacing-20px;
      margin-top: 6px;
      margin-bottom: 6px;
      height: 30px;
      width: 250px;
      border-radius: 20px;
      @include flexer($ai: center, $just: center);
      cursor: pointer;

      &.active {
        background-color: $primary-color-dark;
        border: 1px solid $primary-color-dark;


        .text-link-container {
          justify-content: center;
          width: 100%;
        }
  
        .text-link {
          white-space: nowrap;
          color: $white;
        }

        &.closed {
          border: 1px solid $success-dark;
          background-color: $success-dark;
          
          .text-link {
            color: $white;
          }
        }
      }

      &.closed {
        border: 1px solid $success-dark;

        .text-link {
          white-space: nowrap;
          color: $success-dark;
        }
      }
    }
  }

  .need-summary {
    margin: calc(36px - 6px) 110px $spacing-36px;
    @include flexer($dir: row, $just: flex-start, $ai: center);

    h2.title-1 {
      margin: 0 15px;
    }

    p.body-1-bold {
      color: $success-dark;
    }

    .billing-code-container {
      display: flex;
      flex-grow: 1;
      align-self: stretch;
      justify-content: flex-end;

      .billing-code-select {
        width: 200px;
      }
    }
  }

  .vertical-timeline-container {
    margin-left: 140px;
    margin-right: 111px;
  }
}

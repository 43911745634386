@import "../../App.scss";

.pipeline-home {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: $bg-grey;
  padding: $spacing-36px 53px;

  &-dashboard,
  &-pipelines {
    @include flexer($dir: column, $ai: flex-start, $just: center);
    margin-bottom: $spacing-36px;
  }

  h1.title-1 {
    margin-bottom: $spacing-28px;
    color: $grey-1;
  }

  .action-cards-dashboard,
  .action-cards-pipelines {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: $spacing-28px;
    row-gap: $spacing-28px;
  }
}

.confirm-archive-modal {
  padding: 32px;

  .message {
    margin-bottom: 12px;
  }

  .action-buttons {
    display: flex;
    
    .confirm-btn {
      margin-right: 12px;
    }
  }
}

@import "~gateway-common/src/styles/styles.scss";

.add-need-lightbox {
  .lightbox.edit-lightbox {
    width: 1000px;

    .container {
      .content {
        min-height: 160px;
        position: relative;
        overflow-y: unset;
        z-index: 1;
      }
  
      .lightbox-content {
        padding: $spacing-24px $spacing-36px;
  
  
        .dropdowns-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            z-index: 1;
            margin-bottom: 12px;
        
            .government-categories, .sdoh-categories {
                display: flex;
                flex-direction: column;
                width: 450px;
    
                h3.footnote {
                  font-size: 14px;
                  margin-bottom: 2px
                }
            }
        }
  
        .tags-container {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
  
          .tag {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2px 10px;
              margin-right: 10px;
              border-radius: 4px;
              background-color: #2730d3;
              white-space: nowrap;
              margin-top: 18px;
  
      
              .tag-label {
                font-family: HKGrotesk-SemiBold;
                font-size: 16px;
                color: white;
                white-space: nowrap;
                margin-right: 8px;
              }
      
              .remove-tag-btn {
                border: none;
                background-color: inherit;
                cursor: pointer;
                padding: 0;
      
                &:focus {
                  outline: none
                }
      
                svg {
                  path{
                    fill: rgb(255, 239, 239);  
                  }
                }
              }
          }
        }
      }
    }
  }
}

@import '~gateway-common/src/styles/styles.scss';

.edit-head-of-case-lightbox {
  padding: $spacing-24px $spacing-36px;

  .form-field {
    margin-bottom: $spacing-12px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}
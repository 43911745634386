$spacing-10px: 10px;
$spacing-12px: 12px;
$spacing-16px: 16px;
$spacing-18px: 18px;
$spacing-20px: 20px;
$spacing-24px: 24px;
$spacing-28px: 28px;
$spacing-30px: 30px;
$spacing-36px: 36px;
$spacing-50px: 50px;
$spacing-60px: 60px;
$breakpoint-411px: 411px;
$breakpoint-320px: 320px;

@import "~gateway-common/src/styles/styles.scss";

// colors
$ca-blue: #2730d3;
$ca-red: #e02020;

.Toastify__toast--success {
  background: rgba(7, 110, 9, 0.9);
}
// dimensions
$max-width: 1440px;

.has-max-width {
  max-width: $max-width;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.App {
  display: flex;
  background-color: $bg-grey;
  min-height: 100vh !important;
}

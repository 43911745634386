@import "~gateway-common/src/styles/styles.scss";

.tabs-container {
  display: flex;

  div.tab-contents {
    height: 100%;
    width: 180px;
    text-align: center;
    color: $primary-color-dark;
    line-height: 50px;
    cursor: pointer;
  }

  div.tab-selected {
    border-bottom: 5px solid $primary-color-dark;
  }

  div.tab-unselected {
    &:hover {
      color: $primary-color-light;
      border-bottom: 5px solid $primary-color-light;
    }
  }
}

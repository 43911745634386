@import '~gateway-common/src/styles/styles.scss';

.edit-custom-attributes-lightbox {

  .key-value-fields {
    padding: $spacing-24px $spacing-36px;
    border-bottom: 1px solid $neutral-dark;

    .input-1 {
      margin-bottom: $spacing-12px;
    }

    .delete-btn-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .delete-btn {
        cursor: pointer;
      }
    }

    &:last-child {
        margin-bottom: 0px;
        border-bottom: none;
      }
  }

  .empty-message {
    text-align: center;
    white-space: pre-line;
    padding-top: 62px;
    padding-bottom: 66px;

    &.body-2 {
      color: $grey-1;
    }
  }
}
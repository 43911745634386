@import '~gateway-common/src/styles/styles.scss';

.note-light-box {
  display: flex;
  flex-direction: column;
  padding: $spacing-24px $spacing-36px;

  .text-input-container {
    margin-bottom: $spacing-12px;

    .note-text {
      height: 229px;
      resize: none;
    }
  }

  .related-needs {
    display: flex;
    flex-direction: column;

    & > span {
      color: $neutral-dark;
      display: block;
      margin-bottom: 6px;
      font-size: 12px;
      font-family: HKGrotesk-Bold;
    }

    .related-need-label {
      display: flex;
      align-items: center;
      color: $grey-1;
      cursor: pointer;

      .related-need {
        margin-right: 8px;
        cursor: pointer;
      }
    }
  }
}

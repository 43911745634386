@import "~gateway-common/src/styles/styles.scss";

.referral-detail-panel {
  width: 922px;
  display: flex;
  background-color: $white;
  box-shadow: 0px 2px 4px $neutral-light;
  border-radius: 4px;
  margin-top: $spacing-28px;

  & > .left-section {
    width: 561px;
    border-right: 1px solid $neutral-light;

    & > .top-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $spacing-24px $spacing-20px;
      border-bottom: 1px solid $neutral-light;

      & > .icon-urgency-created-at {
        display: flex;
        align-items: center;

        & > .referral-icon {
          position: relative;
          margin-right: $spacing-12px;

          .case-closed {
            position: absolute;
            bottom: -6px;
            right: 0px;
          }
        }

        & > .urgency {
          margin-right: $spacing-10px;
        }
      }
    }

    & > .bottom-section {
      padding: $spacing-24px $spacing-20px;

      & > .additional-note {
        margin-top: $spacing-20px;
      }
    }
  }

  & > .right-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 361px;
    padding: $spacing-20px;
    z-index: 1;

    div:last-child {
      margin-bottom: 0px;
    }

    & > .heading {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: $spacing-20px;
    }

    .referrer-section {
      display: flex;
      margin-bottom: $spacing-24px;

      & > .pipeline-icon {
        display: flex;
        justify-content: center;
        margin-right: $spacing-20px;
        width: 20px;

        & img {
          width: 19px;
          height: 19px;
        }
      }

      & > .referrer-info {
        & > .full-name {
          line-height: 20px;
          margin-bottom: 4px;
        }
      }
    }

    & > .care-advisor {
      margin-bottom: $spacing-24px;
      display: flex;
      align-items: center;
      z-index: 11;

      & > .care-advisor-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        margin-right: $spacing-20px;
      }

      .care-advisor-dropdown {
        z-index: 2;
        width: 250px;
      }
    }

    & > .priority-need {
      margin-bottom: $spacing-24px;
      display: flex;
      align-items: center;
      
      .body-1-bold {
        line-height: 10px;
      }

      & > .priority-need-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        margin-right: $spacing-20px;
      }

      & > .select-container {
        width: 250px;
      }
    }
  }

  a.phone-link {
    text-decoration: none;

    p {
      color: $primary-color-dark;

      &:hover {
        color: $primary-color-light;
      }
    }
  }
}

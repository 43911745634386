@import "~gateway-common/src/styles/styles";

.breadcrumb {
  @include flexer($dir: row, $ai: center, $just: flex-start);

  .text-link-container {
    margin-right: 11px;
  }

  svg.is-active {
    path {
      stroke: $neutral-dark;
    }
  }

  p.on-page {
    color: $grey-1;

    &:hover {
      color: $grey-1;
    }
  }
}

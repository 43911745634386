@import "~gateway-common/src/styles/styles.scss";

.need-status-modal-overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  overflow: auto;

  .need-status-modal {
    position: absolute;
    top: 25%;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
    margin-top: 74px;
    margin-bottom: 74px;
    opacity: 1;
    z-index: 11;
    outline: none;
    background-color: white;

    .modal-inner {
      display: flex;
      flex-direction: row;

      .left-section {
        height: 498px;
        border-right: 2px solid #f1f3fb;
        padding: 24px 0px 24px 0px;
        overflow-y: scroll;

        .need-status-list {
          padding: 0px 24px 0px 24px;
          border-bottom: 2px solid #f1f3fb;
          margin-bottom: 18px;

          > div {
            margin-bottom: 18px;
          }
        }

        .need-options {
          display: flex;
          flex-direction: column;
          padding: 0 24px 24px 48px;

          .section-label {
            font-family: HKGrotesk-SemiBold;
            color: $grey-2;
            font-size: 14px;
          }

          .need-checkbox {
            font-family: HKGrotesk-SemiBold;
            margin-bottom: 12px;
            font-size: 14px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &.disabled {
              color: $grey-2;
              opacity: 0.6;
              cursor: default;
            }

            .checkbox {
              margin-right: 8px;
              cursor: pointer;

              &:disabled {
                cursor: default;
              }
            }
          }
        }
      }

      .right-section {
        padding: 24px;

        button {
          margin-top: 12px;
          align-self: flex-start;
        }
      }

      > div {
        display: flex;
        flex-direction: column;
      }
    }

    .close-modal-btn {
      position: absolute;
      right: 0px;
      top: -35px;
      height: 25px;
      width: 25px;
      border-radius: 100%;
      background-color: #dbdbdb;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: #ececec;
      }

      &:active {
        background-color: #d8d8d8;
      }

      svg {
        fill: #36393d;
      }
    }

    .advisor-dropdown {
      margin-bottom: 12px;
    }

    .section-label {
      font-family: HKGrotesk-Medium;
      color: #36393d;
      margin-bottom: 12px;
    }

    .status-dropdown {
      margin-bottom: 24px;
      width: 350px;
    }

    textarea {
      width: 500px;
      height: 220px;
      padding: 14px 16px;
      background-color: #f3f3f3;
      border: solid 0.5px #e0e0e0;
      border-radius: 4px;
      resize: none;
      font-size: 16px;
      font-family: HKGrotesk-SemiBold;
      letter-spacing: -0.48px;
      color: #36393d;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #acacac;
        letter-spacing: -0.48px;
        font-size: 16px;
      }
    }

    .submit-button {
      &:disabled {
        background-color: #dbdbdb;
        color: white;
        border: 1px solid #dbdbdb;
        cursor: not-allowed;
      }
    }
  }
}

@import "~gateway-common/src/styles/styles.scss";

.admin-view {
  margin-left: 20px;
  margin-right: 20px;
  .admin-navbar {
    display: flex;
    margin-top: $spacing-24px;
    height: 50px;
    width: 100%;
    background-color: $white;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid $neutral-light;
  }
}

@import "~gateway-common/src/styles/styles.scss";

.referral-notes-card {
  width: 100%;
  padding: $spacing-24px $spacing-20px;
  border: 1px solid $neutral-light;
  border-radius: 10px;
  margin-bottom: $spacing-28px;

  .emphasized {
    color: $success-dark;
    font-weight: bold;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &-header {
    @include flexer($dir: row, $ai: center, $just: space-between);

    svg {
      height: 20px;
      width: 18px;
      margin-right: 9px;
    }

    &-text {
      width: 80%;
      @include flexer($dir: row, $ai: center, $just: flex-start);
    }
  }

  &-content {
    h3.body-1-bold {
      color: $secondary-color-dark;
      margin: $spacing-24px 0;
    }

    p.body-1 {
      white-space: pre-line;
    }
  }

  &-footer {
    @include flexer($dir: row, $ai: center, $just: space-between);
    margin-top: $spacing-24px;

    p.body-1 {
      color: $grey-2;
    }
  }
}

@import "~gateway-common/src/styles/styles.scss";

.attachments-container {
  width: 470px;
  padding: $spacing-24px $spacing-36px;

  .dropzone {
    justify-content: center;
    height: 86px;
    background-color: $grey-3;
    border: 1px dashed $neutral-dark;

      .body-2 {
        color: $grey-1;
      }
    }

  .error-msg {
    margin-top: $spacing-24px;
    width: 100%;
    text-align: center;
    color: $warning-dark;
    font-size: 14px;
    line-height: 33px;
    font-family: HKGrotesk-SemiBold, sans-serif;
  }

  .spinner-container {
    display: flex;
    margin-top: $spacing-24px;
    justify-content: center;
    width: 100%;

    i {
      font-size: 32px;
      color: $neutral-dark;
    }
  }
}

@import "./colors.scss";

.searchbar-1 {
  width: 100%;
  height: 30px;
  border: 1px solid $neutral-dark;
  border-radius: 20px;
  background-color: $white;
  background: $white url("~gateway-common/src/assets/icon-search.svg") no-repeat
    12px;
  padding-left: 40px;
  color: $grey-1;

  &::placeholder {
    color: $neutral-dark;
  }

  &:focus {
    outline: none;
    border-color: $primary-color-dark;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  }
}

.input-1 {
  input,
  textarea {
    width: 100%;
    height: 30px;
    font-size: 14px;
    line-height: 33px;
    letter-spacing: 0.2px;
    color: $grey-1;
    border: 1px solid $neutral-dark;
    border-radius: 4px;
    padding-left: 12px;
    outline: none;

    ::placeholder {
      color: $neutral-dark;
    }

    &.error {
      border: 1px solid $warning-dark;
    }

    &.text-link {
      color: $grey-1;

      &:hover {
        color: $grey-1;
      }
    }
  }
  > span {
    color: $neutral-dark;
    display: block;
    margin-bottom: 6px;
    font-size: 12px;
    font-family: HKGrotesk-Bold;
    span {
      font-size: 36px;
      color: $warning-dark;
      vertical-align: bottom;
      line-height: 8px;
    }
  }
  &.has-value {
    input,
    textarea {
      background-color: $grey-3;
    }
  }
  &.is-focus {
    input:not(.error),
    textarea:not(.error) {
      border: 1px solid $primary-color-dark;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    }
    > span {
      color: $primary-color-dark;
    }
  }
}

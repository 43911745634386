@import "~gateway-common/src/styles/styles.scss";

.referral-notes-container {
  background-color: $white;
  width: 100%;
  padding: $spacing-36px $spacing-20px;

  .note-utilities {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-36px;

    & > .searchbar-1 {
      width: 274px;
      margin-right: $spacing-12px;
    }

    & > .dropdown-container {
      position: relative;
      width: 250px;
      margin-right: $spacing-18px;

      & > .dropdown-title {
        position: absolute;
        bottom: 100%;
      }
    }

    & .clear-btn {
      line-height: 20px;
      cursor: pointer;
    }

    .add-note-btn-container {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;

      .add-note-btn {
        display: flex;
        align-items: center;
  
        .add-note-btn-text {
          width: 100%;
          text-align: center;
          color: $white;
        }
      }
    }
  }
}

// Flexbox container generator
@mixin flexer(
  $disp: flex,
  $dir: null,
  $wrap: null,
  $just: null,
  $grow: null,
  $ai: null,
  $ac: null,
  $as: null
) {
  display: $disp;
  flex-direction: $dir;
  flex-wrap: $wrap;
  justify-content: $just;
  flex-grow: $grow;
  align-items: $ai;
  align-content: $ac;
  align-self: $as;
}

// media queries wrapper
@mixin wrapper-mq($range: "low") {
  // >=411px
  @if $range == "high" {
    @media (min-width: $breakpoint-411px) {
      @content;
    }
  }
  // >=320px and <411px
  @else if $range == "mid" {
    @media (min-width: $breakpoint-320px) and (max-width: $breakpoint-411px - 1) {
      @content;
    }
  }
  // "low" (default) -- <320px
  @else {
    @media (max-width: $breakpoint-320px - 1) {
      @content;
    }
  }
}

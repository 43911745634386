@import "../../App.scss";

.referrals {
  width: 100vw;
  padding: 0px 0px 36px;
  display: flex;
  flex-direction: column;
  .referral-total-count {
    padding-bottom: 15px;
  }
  .date-disabled {
    cursor: not-allowed;
    background-color: $grey-5;
  }
  .required-label {
    font-size: 24px;
    color: $warning-dark;
  }
  .d-flex {
    display: flex;
  }
  .input-1 {
    input {
      border-radius: 20px;
    }
  }
  .referral-select {
    position: relative;
    margin-right: $spacing-12px;

    &.sort-by-select-container {
      padding: 30px 0px;
      background-color: $white;
      display: flex;
      align-items: center;
      width: 100%;

      h3 {
        position: static;
        margin-right: 8px;
      }

      .sort-by-select {
        width: 270px;
      }

      .date-select-container {
        @include flexer($just: center, $ai: center);

        &.unselected {
          h3 {
            color: $neutral-dark;
          }
        }

        &.selected {
          h3 {
            color: $primary-color-dark;
          }
        }

        h3 {
          margin-left: $spacing-10px;
        }

        span {
          display: none;
        }

        .input-1 {
          width: 160px;

          .text-link {
            color: $neutral-dark;
          }

          .has-value {
            background-color: $white;
            color: $primary-color-dark;
            border-color: $primary-color-dark;
          }
        }
      }
    }

    .select-container {
      width: 100%;
      height: 30px;

      .select-value-container {
        height: 100%;
        display: flex;
        align-items: center;
        border: 1px solid $neutral-dark;
        border-radius: 20px;
        padding: 0px 12px;
        color: $neutral-dark;
        background-color: $white;
        background-repeat: no-repeat;
      }

      .select-menu {
        color: $grey-1;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
        text-align: left;
      }
    }

    &.selected {
      h3 {
        color: $primary-color-dark;
      }

      .select-value-container {
        border-color: $primary-color-dark;
        color: $primary-color-dark;

        svg {
          path {
            stroke: $primary-color-dark;
          }
        }
      }

      .select-menu {
        .selected-option {
          color: $primary-color-dark;
        }
      }
    }
  }
  div.text-link.reset-btn {
    display: inline-block;
    margin-left: $spacing-24px;
    cursor: pointer;
    line-height: 30px;
  }

  .top-section {
    position: sticky;
    top: 60px;
    z-index: 10;
    padding-bottom: $spacing-24px;
    background-color: $grey-3;

    .utilities-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      background-color: $white;
      padding: 30px 28px;
      border-bottom: 1px solid $neutral-light;
  
      .patient-search-container {
        width: 97%;
        .text-link {
          color: $grey-1;
        }
      }
      .referral-filters-top {
        width: 100%;
        display: flex;
        .patient-search-container{
          width: 50%;
        }
        .filter_part{
          display:flex;
          width: 50%;
          align-items: flex-end;
          justify-content:space-between;
          .apply-btn-container{
            margin-left: 20px;
          }
          img {
            width: 25px;
            height: 25px;
            cursor: pointer;
          }

        }
        
      }
      .referral-filters {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-top: 30px;
        margin-bottom: 20px;
        row-gap: 30px;
        position: relative;
      }
  
      .apply-btn-container {
        display: flex;
        align-items: flex-end;
        .body-1-bold {
          color: $white;
        }
      }
    }
  }
  .referral-close-date {
    display: flex;
  }
.referral-close-date-select {
  width: 50%;
  .input-1 {
    position: relative;
    top: -5px;
    input {
      padding-right: 5px;
      color: $neutral-dark;
    }
  }
}
  .referrals-container {
    width: 1280px;
    margin: auto;
    z-index: 9;
    .referrals-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;

      &.unassigned-section {
        z-index: 5;
        margin-bottom: 10px;
      }

      &.ongoing-section {
        z-index: 4;
        margin-bottom: 10px;
      }

      &.pending-section {
        z-index: 3;
        margin-bottom: 10px;
      }

      &.followup-section {
        z-index: 2;
      }

      &.closed-section {
        z-index: 1;
        margin-bottom: 10px;

        .closed-section-heading-container {
          width: 100%;
          margin: $spacing-10px 0;
          @include flexer($ai: center, $just: space-between);
          position: sticky;
          top: 430px;

          .closed-section-pagination {
            width: 300px;
            height: 100%;
            @include flexer($ai: center, $just: flex-end);

            .select-container {
              width: 90px;
              height: 30px;
              padding: 0 $spacing-12px;

              .select-value-container {
                height: 100%;
                @include flexer($ai: center);
                border: 1px solid $neutral-dark;
                border-radius: 4px;
                padding: 0px 12px;
                color: $neutral-dark;
                background-color: $white;
                background-repeat: no-repeat;
              }

              .select-menu {
                color: $grey-1;
                box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
                text-align: left;
              }
            }

            &.selected {
              h3 {
                color: $primary-color-dark;
              }

              .select-value-container {
                border-color: $primary-color-dark;
                color: $primary-color-dark;

                svg {
                  path {
                    stroke: $primary-color-dark;
                  }
                }
              }

              .select-menu {
                .selected-option {
                  color: $primary-color-dark;
                }
              }
            }
          }
        }
        .closed-section-heading-container_dropdown {
          width: 100%;
          margin: $spacing-10px 0;
          @include flexer($ai: center, $just: space-between);
          position: sticky;
          top: 175px;

          .closed-section-pagination {
            width: 300px;
            height: 100%;
            @include flexer($ai: center, $just: flex-end);

            .select-container {
              width: 90px;
              height: 30px;
              padding: 0 $spacing-12px;

              .select-value-container {
                height: 100%;
                @include flexer($ai: center);
                border: 1px solid $neutral-dark;
                border-radius: 4px;
                padding: 0px 12px;
                color: $neutral-dark;
                background-color: $white;
                background-repeat: no-repeat;
              }

              .select-menu {
                color: $grey-1;
                box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
                text-align: left;
              }
            }

            &.selected {
              h3 {
                color: $primary-color-dark;
              }

              .select-value-container {
                border-color: $primary-color-dark;
                color: $primary-color-dark;

                svg {
                  path {
                    stroke: $primary-color-dark;
                  }
                }
              }

              .select-menu {
                .selected-option {
                  color: $primary-color-dark;
                }
              }
            }
          }
        }
      }

      // remove link decorations in ReferralRow sub-components
      .referral-rows-container {
        a {
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }
      }

      .section-heading {
        display: flex;
        cursor: pointer;
        position: sticky;
        top: 430px;
        width: 100%;
        background-color: $secondary-dark;
        padding: 8px 15px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        z-index: 5;
        box-shadow: -4px 0px $grey-3, 4px 0px $grey-3;

        h1 {
          text-align: left;
          color: $white;
          .num-referrals {
            color: $white;
            margin-right: $spacing-12px;
          }
        }

        .chevron-container {
          display: flex;
          margin: auto 0px;
          svg {
            path {
              stroke: white;
            }
          }
        }
      }
      .section-heading_dropdown {
        display: flex;
        cursor: pointer;
        position: sticky;
        top: 175px;
        width: 100%;
        background-color: $secondary-dark;
        padding: 8px 15px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        z-index: 5;
        box-shadow: -4px 0px $grey-3, 4px 0px $grey-3;

        h1 {
          text-align: left;
          color: $white;
          .num-referrals {
            color: $white;
            margin-right: $spacing-12px;
          }
        }

        .chevron-container {
          display: flex;
          margin: auto 0px;
          svg {
            path {
              stroke: white;
            }
          }
        }
      }
    }
  }
}

@import "../../App.scss";

.top-nav {
  background: $grey-1;
  height: 60px;
  @include flexer($ai: center);

  & nav {
    @include flexer($dir: row, $ai: center);
    padding: 0 34px 0 23px;
    width: 100%;
    height: 100%;
  }

  .title-logo {
    color: $white;
    min-width: 200px;
    margin: 0px 50px 0px 8px;
    @include flexer($ai: center);

    img {
      width: 205px;
    }
  }

  a.body-1-bold {
    color: $white;
    text-decoration: none;
    width: 100%;
  }

  .subheadings {
    @include flexer($just: flex-end, $ai: center);
    width: 100%;
    height: 100%;

    ul {
      @include flexer($ai: center);
      height: 100%;

      li.subheading-text {
        width: 134px;
        height: 100%;
        cursor: pointer;

        &-report {
          width: 119px;
        }

        &:hover {
          background-color: $primary-color-light;
        }
      }

      li {
        a {
          height: 100%;
          @include flexer($ai: center, $just: center);
        }
      }

      li.current-location {
        background-color: $primary-color-dark;

        &:hover {
          background-color: $primary-color-dark;
        }
      }

      .button-secondary-small-1-onnav {
        margin-left: 32px;
        margin-right: 35px;

        .text-link {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }

      .button-secondary-small-1.current-location {
        background-color: $primary-color-dark;
        border: 1px solid $primary-color-dark;

        .text-link {
          color: $white;
        }
      }

      li {
        @include flexer($just: center, $ai: center);
        list-style-type: none;
        height: 100%;

        .dropdown-container {
          position: relative;
          height: 100%;

          &:hover {
            cursor: pointer;
          }

          .profile-icon {
            background-color: inherit;
            border: none;
            cursor: pointer;
            width: 50px;
            height: 100%;
            @include flexer($dir: row, $ai: center, $just: space-between);
          }

          .profile-dropdown {
            position: absolute;
            @include flexer($dir: column, $just: space-between, $ai: center);
            z-index: 1;
            width: 136px;
            right: -32px;
            top: 55px;
            background-color: $white;
            border-radius: 8px;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
            padding: 18px 23px;

            a {
              text-decoration: none;
              height: 100%;
            }

            .text-link:hover {
              width: 100%;
              color: $primary-color-light;
            }
          }
        }
      }
    }
  }
}

.account-creation-form {
  padding: 24px;

  .account-item {
    margin-bottom: 12px;
  }

  label {
    input {
      margin-right: 12px;
      margin-bottom: 12px;
    }
  }
}

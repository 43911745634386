@import "./colors.scss";

.gateway-platform,
.hospital-gateway {
  .title-1 {
    color: $black;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0;
    font-family: HKGrotesk-Bold, sans-serif;
  }

  .title-2 {
    color: $black;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0;
    font-family: HKGrotesk-Bold, sans-serif;
  }

  .body-1 {
    font-family: HKGrotesk-Medium;
    font-size: 16px;
    color: $grey-1;
    font-weight: 400;
    letter-spacing: 0;
  }

  .body-1-bold {
    color: $grey-1;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0;
    font-family: HKGrotesk-Bold, sans-serif;
  }

  .body-2 {
    color: $grey-2;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0;
    font-family: HKGrotesk-Medium, sans-serif;
  }

  .text-link {
    color: $primary-color-dark;
    font-size: 14px;
    line-height: 33px;
    letter-spacing: 0;
    font-family: HKGrotesk-SemiBold, sans-serif;

    &:hover {
      color: $primary-color-light;
    }

    &.inactive,
    &.inactive:hover {
      color: $neutral-light;
    }
  }

  .footnote {
    color: $neutral-dark;
    font-size: 12px;
    line-height: 15.64px;
    letter-spacing: 0.5px;
    font-family: HKGrotesk-Bold, sans-serif;
  }

  .data-1 {
    color: $secondary-color-dark;
    font-size: 60px;
    line-height: 36px;
    letter-spacing: 0;
    font-family: HKGrotesk-Regular, sans-serif;
  }
}

.allied-benefits {
  .title-1 {
    color: $black;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0;
    font-family: "Roboto-Bold", sans-serif;
  }

  .title-2 {
    color: $black;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0;
    font-family: "Roboto-Medium", sans-serif;
  }

  .body-1 {
    color: $grey-1;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0;
    font-family: "Roboto-Regular", sans-serif;
  }

  .body-1-bold {
    color: $grey-1;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0;
    font-family: "Roboto-Bold", sans-serif;
  }

  .body-2 {
    color: $grey-1;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    font-family: "Roboto-Regular", sans-serif;
  }

  .body-2-bold {
    color: $grey-1;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    font-family: "Roboto-Bold", sans-serif;
  }

  .body-3 {
    color: $grey-1;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.5px;
    font-family: "Roboto-Regular", sans-serif;
  }

  .special-text {
    color: $black;
    font-size: 40px;
    line-height: 44px;
    letter-spacing: 0;
    font-family: "Roboto-Light", sans-serif;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  overflow-y: scroll;
}

button.action-button {
  font-family: HKGrotesk-Medium;
  font-size: 14px;
  letter-spacing: -0.4px;
  color: #ffffff;
  width: 99px;
  height: 22px;
  border-radius: 14.2px;
  cursor: pointer;
  &.green {
    border: solid 0.7px #459c0d;
    background-color: #459c0d;
  }
  &.red {
    border: solid 0.7px #e9492a;
    background-color: #e9492a;
  }
}

@font-face {
  font-family: HKGrotesk-Medium;
  src: local(HKGrotesk-Medium),
    url(./fonts/hk-grotesk/HKGrotesk-Medium.otf) format("opentype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: HKGrotesk-SemiBold;
  src: local(HKGrotesk-SemiBold),
    url(./fonts/hk-grotesk/HKGrotesk-SemiBold.eot) format("embedded-opentype"),
    url(./fonts/hk-grotesk/HKGrotesk-SemiBold.otf) format("opentype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: HKGrotesk-Bold;
  src: local(HKGrotesk-Bold),
    url(./fonts/hk-grotesk/HKGrotesk-Bold.eot) format("embedded-opentype"),
    url(./fonts/hk-grotesk/HKGrotesk-Bold.otf) format("opentype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: HKGrotesk-Regular;
  src: local(HKGrotesk-Regular),
    url(./fonts/hk-grotesk/HKGrotesk-Regular.otf) format("opentype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

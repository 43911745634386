@import '~gateway-common/src/styles/styles.scss';

.personal-info-edit {
  > div {
    > div:last-child {
      margin-bottom: $spacing-24px;
    }
  }
  input {
    font-family: HKGrotesk-Medium;
  }
}

@import "../../App.scss";

.referral-detail-container {
  position: relative;
  width: 100vw;
  height: 100%;
  @include flexer($dir: row, $just: flex-end);

  .patient-info-panel-container {
    position: fixed;
    left: 0;
  }

  .referral-detail {
    @include flexer($dir: column, $ac: center);
    margin: $spacing-18px 0 $spacing-50px;
    padding-left: 60px;
    width: calc(100vw - 451px);
  }
}

@import '~gateway-common/src/styles/styles';

.lightbox {
  &.status-edit-lightbox {
    width: 470px;
    padding: 24px;
    .container {
      position: relative;
      .status-dropdown {
        margin-bottom: 24px;
        width: 100%;
      }
      .section-label {
        padding-bottom: 15px;
      }
      textarea {
        width: 100%;
        height: 220px;
        padding: 14px 16px;
        background-color: #f3f3f3;
        border: solid 0.5px #e0e0e0;
        border-radius: 4px;
        resize: none;
        font-size: 16px;
        font-family: HKGrotesk-SemiBold;
        letter-spacing: -0.48px;
        color: #36393d;
        margin-bottom: 24px;
        &:focus {
          outline: none;
        }
        
        &::placeholder {
          color: #acacac;
          letter-spacing: -0.48px;
          font-size: 16px;
        }
      }
      // footer
      > div:last-child {
        display: flex;
        align-items: center;
        // padding: $spacing-24px 0;
        // border-top: 1px solid $neutral-dark;
        
        .text-link {
          line-height: 16px;
          cursor: pointer;
          padding: $spacing-10px;
        }
      }
    }
  }
}
  
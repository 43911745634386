@import "~gateway-common/src/styles/styles.scss";

.patient-info-panel {
  width: 451px;
  height: 100vh;
  background-color: $white;
  box-shadow: 0 2px 4px $neutral-light;
  position: relative;
  padding-top: 122px;

  .scrollable-section {
    overflow-y: scroll;
    height: calc(100vh - 182px);
    position: absolute;
    top: 122px;
    width: 100%;

    > div:not(:last-child) {
      border-bottom: 1px solid $neutral-light;
    }
  }

  > div:not(:last-child) {
    border-bottom: 1px solid $neutral-light;
  }
  .header-section {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 122px;
    width: inherit;
    padding: 0px $spacing-24px;
    background-color: $white;

    .button-primary-big {
      color: $white;
    }
    > div:first-child {
      display: flex;
      flex-direction: row;
    }
    svg {
      margin-right: 15px;
    }
    a {
      text-decoration: none;
      color: $primary-color-dark;
      line-height: 33px;
      font-size: 14px;
      font-family: HKGrotesk-Medium;
      svg {
        margin-left: 11px;
      }
    }

    .tag-closed {
      margin-top: 4px;
    }
  }

  .confidential-tag {
    font-family: HKGrotesk-Bold;
    color: $white;
    font-size: 14px;
    line-height: 14px;
    padding: 4px 6px;
    border-radius: 3px;
    background-color: $secondary-color-dark;
  }

  .panel-section {
    padding: $spacing-24px;
  }

  div.panel-section {
    &:not(.attachments) {
      > div:first-child {
        margin-bottom: $spacing-12px;
      }
    }
    > div:first-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    p {
      @extend .body-1;
      line-height: 30px;
    }

    a.phone-link {
      text-decoration: none;

      p {
        color: $primary-color-dark;

        &:hover {
          color: $primary-color-light;
        }
      }
    }
  }
  .attachments {
    .footnote {
      margin-bottom: $spacing-24px;
    }
  }
}

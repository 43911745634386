@import "../../App.scss";

.referral-row {
  display: flex;
  width: 1280px;
  background-color: $white;
  box-shadow: 0px 2px 4px $neutral-light;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;

  .section {
    padding: $spacing-24px $spacing-20px;
    border-right: 4px solid $grey-3;

    &:last-child {
      border: none;
    }
  }

  .left-section {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .urgency-created-at {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: $spacing-20px;

      .created-at {
        margin-left: $spacing-12px;
      }
    }

    .pending-message {
      color: $warning-dark;
      margin: $spacing-20px 0px;
      text-align: left;

      span {
        white-space: nowrap;
      }
    }

    .tag-container {
      margin: $spacing-20px 0px;
    }

    .pipeline-icon {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
    }
  }

  .left-middle-section {
    width: 328px;
    text-align: left;

    .info-item {
      margin-bottom: $spacing-10px;
    }

    a.phone-link {
      text-decoration: none;

      p {
        color: $primary-color-dark;

        &:hover {
          color: $primary-color-light;
        }
      }
    }
  }

  .right-middle-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 359px;

    .timeline-container {
      width: 100%;

      .timeline-title {
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin: $spacing-20px 0px;

        .timeline-category {
          text-align: left;
          color: $grey-1;
          margin-right: $spacing-10px;
        }

        .closed-date {
          color: $success-dark;
          font-family: HKGrotesk-Bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &:first-child {
        .timeline-title {
          margin-top: 0px;
        }
      }
    }
  }

  .right-section {
    width: 292px;

    .referrer-container,
    .assigned-care-advisor {
      @include flexer($just: flex-start, $dir: row, $ai: center);
    }

    .care-advisors-logo {
      width: 19px;
      height: 19px;
    }

    .pipeline-img {
      width: 19px;
      height: 19px;
    }

    .item-container {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      &.priority-need {
        margin-top: $spacing-20px;
      }

      .icon-container {
        display: flex;
        align-items: center;
        margin-right: $spacing-20px;
      }

      .advisor-dropdown-container {
        width: 190px;
      }

      .item-select-container {
        width: 190px;
        height: 30px;
        z-index: unset;

        .item-select-value-container {
          height: 100%;
          display: flex;
          font-size: 14px;
          align-items: center;
          border: 1px solid $primary-color-dark;
          border-radius: 4px;
          padding: 0px 12px;
          color: $primary-color-dark;
          background-color: $white;

          svg {
            path {
              stroke: $primary-color-dark;
            }
          }
        }

        .item-select-menu {
          color: $grey-1;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
          text-align: left;
          z-index: 100;
        }
      }
    }

    .archive-btn-container {
      display: flex;
      justify-content: center;
      margin-top: $spacing-20px;
    }
  }
}
